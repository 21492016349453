.login {
  background-image: url("../assets/image/bg.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  background-color: #f7fafc;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login .login-wrap {
  position: relative;
}

.login .login-wrap .form-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 408px;
  background: #ffffff;
  box-shadow: 0px 5px 25px rgba(200, 221, 227, 0.5);
  border-radius: 24px 0px 0px 24px;
  padding: 50px 30px 20px;
}

.login .login-wrap .form-card .title {
  height: 28px;
  margin-bottom: 32px;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
  display: flex;
  align-items: center;
  text-align: center;
  color: #465767;
}

.login .login-wrap .form-card .img-login {
  position: absolute;
  background-color: #ffffff;
  padding: 6px;
  width: 70px;
  height: 70px;
  border-radius: 64px;
  top: -32px;
  box-shadow: 0px 2px 10px rgba(200, 221, 227, 0.4);
}

.login .login-wrap .form-card .inpt-login {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  margin-bottom: 16px;
  width: 330px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #dae7f3;
  box-sizing: border-box;
  border-radius: 8px;
}

.login .login-wrap .form-card .inpt-login input {
  padding: 10px;
  width: 100%;
  font-size: 14px;
  color: #465767;
  border: none;
  outline: none;
}

.input-wrapper {
  position: relative;
  width: 100%;
  font-size: 14px;
}

.inpt-login-email {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  margin-bottom: 16px;
  width: 100%;
  height: 56px;
  background: transparent;
  border: 1px solid #dae7f3;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  padding-left: 40px;
  outline: none;
}

.inpt-login-email:focus {
  border: 2px solid #6AA4FF;
}

.icon-email {
  position: absolute;
  left: 15px;
  top: 22px;
  z-index: 10;
}

.inpt-login-password {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  margin-bottom: 16px;
  width: 100%;
  height: 56px;
  background: transparent;
  border: 1px solid #dae7f3;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  padding-left: 40px;
  outline: none;
}

.inpt-login-password:focus {
  border: 2px solid #6AA4FF;
}

.icon-pw {
  position: absolute;
  left: 15px;
  top: 20px;
  z-index: 10;
}

.icon-eye {
  position: absolute;
  right: 15px;
  top: 22px;
  cursor: pointer;
}

.login .login-wrap .form-card .lupa-pwd {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #2b5ba5;
  cursor: pointer;
}

.lupa-pwd:hover {
  text-decoration: underline;
}

.btn-login {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  text-align: center;
  background: #2b5ba5;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 30px;
  height: 50px;
}

.btn-loading {
  background-color: white;
  border: 1px solid #2b5ba5;
  color: #2b5ba5;
  margin-top: 30px;
}

.login .login-wrap .form-card .btn-login-wait {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  background: #d3d4d6;
  border-radius: 8px;
}

.login .login-wrap .form-card .err {
  color: #ff004c;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: small;
  text-align: left;
}

.img-side {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 408px;
  background: #ffffff;
  border-radius: 0px 24px 24px 0px;
  padding: 50px 20px 20px;
  z-index: 5;
  color: white;
  background: linear-gradient(191.94deg, #18D2EB 24.57%, #3360A7 76.97%);
}

.img-side img {
  z-index: 5;
  margin-bottom: 15px;
}

.img-side h2 {
  font-size: 18px;
  margin-bottom: 5px;
}

.img-side p {
  font-size: 14px;
}

.img-side::after {
  content: url(../assets/image/bg-img-side.svg);
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 1000px) {
  .img-side {
    display: none;
  }

  .login .login-wrap .form-card {
    border-radius: 24px;
    font-size: 14px;
  }
}

.home {
  background-color: #f7fafc;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f7fafc;
}

.card-detail {
  box-shadow: 0px 2px 10px rgba(233, 233, 233, 0.8);
  border-radius: 16px;
  -webkit-box-shadow: 0px 2px 10px rgba(233, 233, 233, 0.8);
  -moz-box-shadow: 0px 2px 10px rgba(233, 233, 233, 0.8);
  display: flex;
  flex-direction: column;
  background-color: white;
}

.card-detail .title {
  width: calc(100% - 64px);
  text-align: left;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  text-transform: capitalize;
  color: #465767;
  margin-bottom: 0;
}

.card-detail .widen-card {
  background: #ffffff;
  width: calc(100% - 64px);
  min-height: 100px;
  border: 1px solid #dae7f3;
  box-sizing: border-box;
  border-radius: 16px;
  margin-bottom: 24px;
  transition: all 0.2s ease-in-out;
}

.card-detail .widen-card-active {
  background: #ffffff;
  width: calc(100% - 64px);
  min-height: 250px;
  border: 1px solid #dae7f3;
  box-sizing: border-box;
  border-radius: 16px;
  margin-bottom: 24px;
  transition: all 0.2s ease-in-out;
}

.card-detail .widen-card .narrow {
  padding: 32px 24px 32px 24px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.card-detail .widen-card .narrow h3 {
  color: #465767;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}

.card-detail .widen-card-active .expand {
  padding: 32px 24px 32px 24px;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: capitalize;
  color: #465767;
}

.card-detail .widen-card-active .expand .head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.head h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.icon-arrow-down {
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.icon-arrow-down::before {
  content: url(/src/assets/icon/arrowDown.svg);
}

.icon-arrow-up {
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.icon-arrow-up::before {
  content: url(/src/assets/icon/arrowUp.svg);
}

.detail-header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 24px 10px;
  height: 175px;
  background-color: #ffffff;
  color: #465767;
  margin-bottom: 26px;
  box-shadow: 0px 5px 25px rgba(200, 221, 227, 0.5);
  border-radius: 16px;
  background-image: url("../assets/image/bg-pt.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.detail-header h1 {
  font-size: 24px;
}

.detail-header p {
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.detail-header-hki {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 24px 10px;
  height: 175px;
  background-color: #ffffff;
  color: #465767;
  margin-bottom: 26px;
  box-shadow: 0px 5px 25px rgba(200, 221, 227, 0.5);
  border-radius: 16px;
  background-image: url("../assets/image/bg-hki.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.detail-header-hki h1 {
  font-size: 24px;
}

.btn-back {
  position: absolute;
  top: 24px;
  left: 24px;
  width: 36px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #dae7f3;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-image: url(../assets/icon/arrowLeft.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.btn-back:hover {
  background-color: #2b5ba5;
  background-image: url(../assets/icon/arrow-white.svg);
  background-repeat: no-repeat;
}

svg:hover {
  fill: white;
}

.detail-header h1 {
  color: #465767;
  font-size: 30px;
  margin-bottom: 0;
}

.detail-header p {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.detail-header-hki h1 {
  color: #465767;
  font-size: 30px;
  margin-bottom: 0;
}

.detail-header-hki p {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.table {
  margin: 0;
}

.btn-print {
  display: flex;
  white-space: nowrap;
  justify-content: flex-start;
  text-align: left;
  align-items: center;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #2b5ba5;
  background: #ffffff;
  border: 1px solid #dae7f3;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
}

.btn-print:hover {
  background-color: #F2F5F9;
}

.btn-mail {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  color: #2b5ba5;
  border: 1px solid #dae7f3;
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
  background-image: url(../assets/icon/mail.svg);
  background-repeat: no-repeat;
  background-position: 10px;
  background-size: 19px;
  font-weight: bold;
  font-size: 14px;
}

.btn-mail:hover {
  color: white;
  background-color: #2b5ba5;
  background-image: url(../assets/icon/mail-white.svg);
}

.btn-company {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  color: #2b5ba5;
  border: 1px solid #dae7f3;
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
  background-image: url(../assets/icon/company.svg);
  background-repeat: no-repeat;
  background-position: 10px;
  font-weight: bold;
  font-size: 14px;
}

.btn-company:hover {
  color: white;
  background-color: #2b5ba5;
  background-image: url(../assets/icon/company-white.svg);
}

.btn-people {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  color: #2b5ba5;
  border: 1px solid #dae7f3;
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
  background-image: url(../assets/icon/people.svg);
  background-repeat: no-repeat;
  background-position: 10px;
  font-weight: bold;
  font-size: 14px;
}

.btn-people:hover {
  color: white;
  background-color: #2b5ba5;
  background-image: url(../assets/icon/people-white.svg);
}

.btn-invest {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  color: #2b5ba5;
  border: 1px solid #dae7f3;
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
  background-image: url(../assets/icon/money.svg);
  background-repeat: no-repeat;
  background-position: 10px;
  font-weight: bold;
  font-size: 14px;
}

.btn-invest:hover {
  color: white;
  background-color: #2b5ba5;
  background-image: url(../assets/icon/money-white.svg);
}

.btn-word {
  padding: 10px;
  margin: 2px;
  width: 100px;
  float: left;
  font-size: 14px;
  background-color: #3f51b5;
  border-radius: 8;
  color: white;
  cursor: pointer;
  font-weight: bold;
}

.btn-word:hover {
  background-color: #F2F5F9;
  color: #2b5ba5;
}

.dropdown {
  width: 650px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 18px;
  box-shadow: 1px 0px 8px 2px rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 1px 0px 8px 2px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 1px 0px 8px 2px rgba(0, 0, 0, 0.29);
}

.home-wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 25px rgba(200, 221, 227, 0.5);
  border-radius: 16px;
  -webkit-box-shadow: 0px 5px 25px rgba(200, 221, 227, 0.5);
  -moz-box-shadow: 0px 5px 25px rgba(200, 221, 227, 0.5);
}

.home-wrapper .header {
  padding: 20px;
}

.home-wrapper .table_data {
  padding: 20px;
  width: 90%;
  table-layout: fixed;
  margin: 0;
}

.table-row  {
  background-color: white;
}

.table-row:hover {
  background-color: #F2F5F9;
}

.accordion-item {
  width: 100%;
  border: 1px solid #DAE7F3 !important;
  box-sizing: border-box;
  border-radius: 16px !important;
}

.toggle {
  font-size: 16px;
  color: #465767;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 15px;
}

.toggle-active {
  font-size: 24px;
  color: #465767;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 15px;
}

.table td, .table th {
  border: none !important;
  padding-left: 0 !important;
  font-size: 14px;
  color: #465767;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  table-layout: fixed;
  width: 90%;
  font-size: 16px;
  border: none;
}

tr, td {
  border: none;
}

td,
th {
  text-align: left;
  padding: 10px;
  border: none;
}

.tbl-btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.generate-btn {
  margin: 10px;
  border: none;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  border-radius: 8px;
  background-color: #3367a1;
  color: white;
}

.sticky-cell {
  position: sticky;
  right: 0;
  color: white;
}

@media (max-width: 1000px) {
  .sticky-cell {
    background-color: white;
  }
}

.btn-dtl {
  cursor: pointer;
  /* background-color: white; */
  width: 100%;
  color: #3f51b5;
  font-weight: bold;
}

.btn-dtl:hover {
  font-weight: bolder;
  transform: scale(1.05);
}

.sticky {
  white-space: normal;
}

.sticky:hover {
  background-color: #F2F5F9;
}

.open-kbli {
  color: #2b5ba5 !important;
  cursor: pointer;
  font-weight: 500;
}
.open-kbli:hover {
  font-weight: bolder;
}

@media (max-width: 991.98px) {
  .home-wrapper {
    width: 90%;
  }

  .detail {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .detail-header-hki h1 {
    font-size: 22px;
  }

  .detail-header {
    padding: 16px 16px 0;
  }

  .detail-header h1 {
    font-size: 22px;
  }

  .detail-header p {
    font-size: 12px;
  }

  .btn-back {
    top: 16px;
    left: 16px;
    width: 30px;
    height: 30px;
  }

  .btn-mail {
    font-size: 13px;
    padding: 10px 12px;
  }

  .btn-people {
    font-size: 13px;
    padding: 10px 12px;
  }

  .btn-company {
    font-size: 13px;
    padding: 10px 12px;
  }
  
  .btn-invest {
    font-size: 13px;
    padding: 10px 12px;
  }

  .toggle {
    font-size: 14px;
  }

  .toggle-active {
    font-size: 17px;
  }
}
