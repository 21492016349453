.nav {
  width: 100%;
  background: #ffffff;
  -webkit-box-shadow: 0 2px 10px #e9e9e9;
  box-shadow: 0 2px 10px #e9e9e9;
}

.nav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-item {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.logout-mobile {
  display: none;
}

.nav-link {
  display: flex;
  flex-wrap: nowrap;
  padding: 8px 0;
}

.nav-link p {
  font-weight: bold;
  margin-bottom: 0;
  font-size: 14px;
}

/* .icon-logout {
  background-image: url(../assets/icon/logout.svg);
  background-repeat: no-repeat;
}

.icon-logout:hover {
  background-image: url(../assets/icon/logout-white.svg);
  background-repeat: no-repeat;
} */

.img {
  padding: 15px 0;
}

.nav .logout {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  font-weight: 600;
  height: 36px;
  color: #2b5ba5;
  border: 1px solid #dae7f3;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  background-image: url(../assets/icon/logout.svg);
  background-repeat: no-repeat;
  background-position: 10px;
}

.nav .logout:hover {
  background-color: #2b5ba5;
  background-image: url(../assets/icon/logout-white.svg);
  background-repeat: no-repeat;
  color: white;
}

.nav .logout span {
  font-weight: bold;
}

.nav .logout svg {
  position: absolute;
  left: 0;
  padding: 10px;
  padding-right: calc(100% - 36px);
  fill: #2b5ba5;
}

.nav .logout svg:hover {
  fill: #ffffff;
}

.nav .navItem {
  width: 171px;
  height: 24px;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #7e97ad;
}

.nav .navItem-active {
  width: 171px;
  height: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #2b5ba5;
}


@media (max-width: 800px) {
  .nav-wrapper {
    flex-wrap: wrap;
  }

  .nav-item {
    width: 100%;
    flex-wrap: wrap;
  }

  .logout-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;
    height: 36px;
    color: #2b5ba5;
    border: 1px solid #dae7f3;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    background-image: url(../assets/icon/logout.svg);
    background-repeat: no-repeat;
    background-position: 10px;
    font-weight: bold;
  }

  .logout-mobile:hover {
    color: white;
    background-color: #2b5ba5;
    background-image: url(../assets/icon/logout-white.svg);
  }

  .nav-link {
    flex-wrap: wrap;
    gap: 10px;
  }

  .nav .logout, .logout img, .logout span {
    display: none;
  }
}