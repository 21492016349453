.header-wrap {
  background-repeat: no-repeat;
  width: 80%;
  height: 100%;
  max-height: 235px;
  margin: 25px 10% 60px 10%;
  border-radius: 8px;
}

.header {
  position: relative;
  background: url("../assets/image/background.png") no-repeat #1A72C2;
  background-repeat: no-repeat;
  width: 100%;
  height: 190px;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  left: 0;
  background-position: center;
  background-size: cover;
  z-index: 10;
  border-radius: 24px;
  margin: 0 auto 20px 0;
}

.header::before {
  content: url(../assets/image/bg-before.svg);
  top: 0;
  left: 0;
  position: absolute;
}

.header::after {
  content: url(../assets/image/bg-after.svg);
  bottom: -7px;
  right: 0;
  position: absolute;
}


.header-hki {
  position: relative;
  background: url("../assets/image/background.png") no-repeat #B82372;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 190px;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  left: 0;
  background-position: center;
  z-index: 10;
  border-radius: 24px;
  margin: 0 auto 20px 0;
}

.header-hki::before {
  content: url(../assets/image/bg-before.svg);
  top: 0;
  left: 0;
  position: absolute;
}

.header-hki::after {
  content: url(../assets/image/bg-after.svg);
  bottom: -7px;
  right: 0;
  position: absolute;
}


h1 {
  font-size: 28px;
  color: white;
  margin-top: 0;
}

.search {
  position: absolute;
  bottom: -50px;
  background: #fff;
  width: 45%;
  height: 52px;
  -webkit-box-shadow: 0 4px 20px rgb(111 111 111 / 15%);
          box-shadow: 0 4px 20px rgb(111 111 111 / 15%);
  border-radius: 35px;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  transform: translate(-50%, -50%);
  left: 50%;
  right: 50%;
  z-index: 5;
}

.icon-src {
  background-color: #2B5BA5;
  padding-top: 10px;
  padding-bottom: 8px;
  padding-right: 16px;
  padding-left: 16px;
  border-radius: 20px;
}

.icon-src::before {
  content: url(/src/assets/icon/search.svg);
}

.search input {
  padding: 10px;
  width: 100%;
  font-size: 14px;
  color: #465767;
  border: none;
  outline: none;
}

.search input::-webkit-input-placeholder {
  font-size: 14px;
  color: #9CAEC4;
}

.search input::-moz-placeholder {
  font-size: 14px;
  color: #9CAEC4;
}

.search input:-ms-input-placeholder {
  font-size: 14px;
  color: #9CAEC4;
}

.search input::-ms-input-placeholder {
  font-size: 14px;
  color: #9CAEC4;
}

.search input::placeholder {
  font-size: 14px;
  color: #9CAEC4;
}

.search button {
  padding: 10px 25px;
  color: white;
  background-color: #df6629;
  -webkit-box-shadow: 0 4px 12px rgb(241 87 9 / 37%);
          box-shadow: 0 4px 12px rgb(241 87 9 / 37%);
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}


@media (max-width: 1440.01px) {
  .header-wrap {
    width: 80%;
    height: 192px;
  }
}

@media (max-width: 1024.01px) {
  .header-wrap {
    width: 80%;
    height: 139px;
  }
}
@media (max-width: 991.98px) {
  .header-wrap {
    width: 100%;
    margin-top: 0;
    margin-left: 0;
    border-radius: 0;
  }

  .search {
    width: 85%;
  }
}

@media (max-width: 767.98px) {
  .header {
    border-radius: 0;
    height: 170px;
  }

  .header::before {
    content: '';
  }
  
  .header::after {
    content: "";
  }

  .header-hki {
    border-radius: 0;
    height: 170px;
  }

  .header-hki::before {
    content: '';
  }
  
  .header-hki::after {
    content: "";
  }
}